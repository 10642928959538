<template>
<div>
  <div class="cloud">
    <h1>{{ $t("cloud.cloud01") }}</h1>
    <h1>{{ $t("cloud.cloud0101") }}</h1>
    <p>{{ $t("cloud.cloud02") }}</p>
    <p>{{ $t("cloud.cloud03") }}</p>
<!--    <p>{{ $t("cloud.cloud04") }}</p>-->
    <div class="quote-button">
      <a href="javascript:;"  @click="getQuote">
        {{ $t("menu.quotation") }}
      </a>
    </div>
  </div>
  <div class="wechat-middle">
      <div class="cloud-01">
        <div>
          <h3>{{ $t("cloud.cloud05") }}</h3>
          <p>{{ $t("cloud.cloud06") }}</p>
        </div>
        <div>
          <h3>{{ $t("cloud.cloud07") }}</h3>
          <p>{{ $t("cloud.cloud08") }}</p>
        </div>
        <div>
          <h3>{{ $t("cloud.cloud09") }}</h3>
          <p>{{ $t("cloud.cloud10") }}</p>
        </div>
        <div>
          <h3>{{ $t("cloud.cloud11") }}</h3>
          <p>{{ $t("cloud.cloud12") }}</p>
        </div>
      </div>
    <div class="cloud-02">
      <div class="cloud-02-left">
        <h2>{{ $t("cloud.cloud13") }}</h2>
        <p>{{ $t("cloud.cloud14") }}</p>
        <div>
          <span><img src="@/assets/img/cloud/right.png"> </span>
          <span>{{ $t("cloud.cloud16") }}</span>
        </div>
        <div>
          <span><img src="@/assets/img/cloud/right.png"> </span>
          <span>{{ $t("cloud.cloud17") }}</span>
        </div>
        <div>
          <span><img src="@/assets/img/cloud/right.png"> </span>
          <span>{{ $t("cloud.cloud18") }}</span>
        </div>
        <div>
          <span><img src="@/assets/img/cloud/right.png"> </span>
          <span>{{ $t("cloud.cloud19") }}</span>
        </div>
        <div>
          <span><img src="@/assets/img/cloud/right.png"> </span>
          <span>{{ $t("cloud.cloud20") }}</span>
        </div>
        <div>
          <span><img src="@/assets/img/cloud/right.png"> </span>
          <span>{{ $t("cloud.cloud21") }}</span>
        </div>
        <div>
          <span><img src="@/assets/img/cloud/right.png"> </span>
          <span>{{ $t("cloud.cloud22") }}</span>
        </div>
        <div>
          <span><img src="@/assets/img/cloud/right.png"> </span>
          <span>{{ $t("cloud.cloud23") }}</span>
        </div>
      </div>
      <div class="cloud-02-right">
        <img src="@/assets/img/cloud/Cloud.png">
      </div>
    </div>

    <div class="cloud-03  padding-40">
      <h1>{{ $t("cloud.cloud66") }}</h1>
      <div class="cloud-list">
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud24") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/02.png">
          <p>{{ $t("cloud.cloud25") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/03.png">
          <p>{{ $t("cloud.cloud26") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/04.png">
          <p>{{ $t("cloud.cloud27") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/05.png">
          <p>{{ $t("cloud.cloud28") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/06.png">
          <p>{{ $t("cloud.cloud29") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/07.png">
          <p>{{ $t("cloud.cloud30") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/08.png">
          <p>{{ $t("cloud.cloud31") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/09.png">
          <p>{{ $t("cloud.cloud32") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/10.png">
          <p>{{ $t("cloud.cloud33") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/11.png">
          <p>{{ $t("cloud.cloud34") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/12.png">
          <p>{{ $t("cloud.cloud35") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/13.png">
          <p>{{ $t("cloud.cloud36") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/14.png">
          <p>{{ $t("cloud.cloud37") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/15.png">
          <p>{{ $t("cloud.cloud38") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud39") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud40") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud41") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud42") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud43") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud44") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud45") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud46") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud47") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud48") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud49") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud50") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud51") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud52") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/01.png">
          <p>{{ $t("cloud.cloud53") }}</p>
        </div>
      </div>
    </div>


    <div class="cloud-04">
      <div class="cloud-04-list">
        <div>
          <img src="@/assets/img/cloud/16.png">
          <h3>{{ $t("cloud.cloud54") }}</h3>
          <p>{{ $t("cloud.cloud55") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/17.png">
          <h3>{{ $t("cloud.cloud56") }}</h3>
          <p>{{ $t("cloud.cloud57") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/18.png">
          <h3>{{ $t("cloud.cloud58") }}</h3>
          <p>{{ $t("cloud.cloud59") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/19.png">
          <h3>{{ $t("cloud.cloud60") }}</h3>
          <p>{{ $t("cloud.cloud61") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/20.png">
          <h3>{{ $t("cloud.cloud62") }}</h3>
          <p>{{ $t("cloud.cloud63") }}</p>
        </div>
        <div>
          <img src="@/assets/img/cloud/21.png">
          <h3>{{ $t("cloud.cloud64") }}</h3>
          <p>{{ $t("cloud.cloud65") }}</p>
        </div>
      </div>
    </div>


    <div class="cloud-05 padding-40">
      <h1>{{ $t("cloud.cloud67") }}</h1>
      <p>{{ $t("cloud.cloud68") }}</p>
      <img src="@/assets/img/cloud/22.png">
    </div>

    <div class="cloud-06">
      <h1>{{ $t("cloud.cloud69") }}</h1>
      <div v-for="(item, index) in items" :key="index" class="cloud-06-item">
        <h3 @click="toggleCollapse(index)" :class="{ active: activeIndex === index }">{{ item.title }}
          <span>
            <img :src="imageURL(index)">
          </span>
        </h3>
        <transition name="collapse">
          <div v-if="activeIndex === index" class="cloud-06-content">
            {{ item.content }}
          </div>
        </transition>
      </div>
    </div>

  </div>

</div>
</template>

<script>
// import { ref } from 'vue';
export default {
  name: "cloud",
  data() {
    return {
      items: [
        {
          title:this.$t("cloud.cloud70"),
          content: this.$t("cloud.cloud71")
        },
        {
          title:this.$t("cloud.cloud72"),
          content: this.$t("cloud.cloud73")
        },
        {
          title:this.$t("cloud.cloud74"),
          content: this.$t("cloud.cloud75")
        },
        {
          title:this.$t("cloud.cloud76"),
          content: this.$t("cloud.cloud77")
        },
        {
          title:this.$t("cloud.cloud78"),
          content: this.$t("cloud.cloud79")
        },
        {
          title:this.$t("cloud.cloud80"),
          content: this.$t("cloud.cloud81")
        },
        {
          title:this.$t("cloud.cloud82"),
          content: this.$t("cloud.cloud83")
        },

      ],
      activeIndex: -1,
      defaultImageURL: require('@/assets/img/cloud/jt-black.png')
    };
  },
  methods: {
    toggleCollapse(index) {
      if (this.activeIndex === index) {
        this.activeIndex = -1;
      } else {
        this.activeIndex = index;
      }
    },
    imageURL(index) {
      if (this.activeIndex === index) {
        return require('@/assets/img/cloud/jt-white.png');
      } else {
        return require('@/assets/img/cloud/jt-black.png');
      }
    },
    getQuote(){
      this.$quote.show(this.$route.name,this.$route.params.lang)
    }
  }
}
</script>

<style scoped>
.padding-40{
  padding-top: 40px;
  padding-bottom: 40px;
}
.cloud{
  background-image:url("~@/assets/img/cloud/Banner.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -84px;
  margin-bottom: 0px;
  padding: 185px 15px 130px 15px;
}
.cloud h1{
  padding-bottom:15px;
  font-size: 43px;
  text-align: center;
  color: #fff;
}
.cloud p{
  text-align: center;
  color: #fff;
  padding: 6px 0;
}
.cloud-01{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F5F5F9;
  border-radius: 10px 10px 10px 10px;
  padding: 40px 80px;
  margin: 40px 0;
}
.cloud-01>div{
  text-align: center;
}
.cloud-01>div p{
  padding-top: 10px;
}
.cloud-01>div h3{
  color: var(--background);
}
.cloud-02{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cloud-02-left, .cloud-02-right{
  width: 50%;
}
.cloud-02-left h2{
  font-size: 34px;
}
.cloud-02-left p{
  padding: 10px 0;
  line-height: 1.8;
}
.cloud-02-left>div{
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.cloud-02-left>div span:first-child{
  margin-right: 10px;
}
.cloud-list{
  display: flex;
  flex-wrap: wrap;
}
.cloud-list>div{
  width: 20%;
   margin: 30px 0;
}
.cloud-list>div img{
   max-width: 150px;
   height: 40px;
   display: block;
   margin:0 auto;
}
.cloud-list>div p{
   text-align: center;
  padding-top:10px;
}
.cloud-04{
  background-color: #F5F5F9;
  border-radius: 10px;
  padding: 40px 80px;
}
.cloud-04-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cloud-04-list>div{
  width:30%;
}
.cloud-06{
  background-color: #F5F5F9;
  border-radius: 10px;
  padding: 40px 80px;
}
.cloud-03 h1{
  text-align: center;
  padding-bottom: 40px;
}
.cloud-04-list>div{
  margin: 40px 0;
}
.cloud-04-list>div img{
  width: 68px;
  display: block;
  margin: 0 auto;
}
.cloud-04-list>div h3{
  text-align: center;
  padding: 20px 0;
}
.cloud-04-list>div p{
  text-align: center;
}
.cloud-05 h1{
   text-align: center;
   padding: 20px 0;
}
.cloud-05 p{
  text-align: center;
}
.cloud-05 img{
  padding: 40px 0;
  max-width: 60%;
  display: block;
  margin: auto;
}
.cloud-06{
  margin-bottom: 40px;
}
.cloud-06 h1{
  text-align: center;
  padding-bottom: 20px;
}
.cloud-06-item h3{
   color: #000;
   background: #fff;
   padding: 10px;
   margin: 20px 0;
   border-radius: 4px;
   cursor: pointer;
}
.cloud-06-item h3.active{
  background: var(--background);
  color: #fff;
}

.cloud-06-content{
  padding: 10px 20px;
}

.collapse-enter-active, .collapse-leave-active {
  transition: height 0.01s ease-out;
}

.collapse-enter, .collapse-leave-to {
  height: 0;
  overflow: hidden;
}
.cloud-06-item h3{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .cloud-01{
    flex-wrap: wrap;
    padding: 20px;
    margin: 10px 0 20px 0;
  }
  .cloud-01>div{
    margin: 15px 0;
  }
  .cloud-02{
    flex-wrap: wrap;
  }
  .cloud-02-left, .cloud-02-right{
    width: 100%;
  }
  .cloud-list{
    flex-wrap: wrap;
  }
  .cloud-list>div{
    width: 50%;
    margin: 10px 0;
  }
  .cloud-04, .cloud-06{
    padding: 20px;
  }
  .cloud-04-list>div{
    width: 100%;
    margin: 20px 0;
  }
  .padding-40{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .cloud-02-left h2, .cloud-03 h1, .cloud-05 h1, .cloud-06 h1{
    font-size: 24px;
  }
  .cloud-06 h1{
    padding-bottom: 0;
  }
  .cloud-06-item h3{
    font-size: 16px;
  }
  .cloud-05 img{
    padding-top: 20px;
    max-width: 100%;
  }
  .cloud h1{
    font-size: 28px;
  }

}


</style>